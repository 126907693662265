<template>
  <div>
    <a-row :gutter="16">
      <a-col :sm="24" :lg="5">
        <div class="card">
          <div class="card-header card-header-flex">
            <div class="d-flex justify-content-between align-items-center w-100">
              <div>
                <h2 class="font-size-18">Menu</h2>
              </div>
            </div>
          </div>
          <div class="card-body">
            <Menu :menus="menus" :menuActive="menuActive" @menu-change="menuChange" />
          </div>
        </div>
      </a-col>
      <a-col :sm="24" :lg="19">
        <div class="card">
          <div class="card-header card-header-flex">
            <div class="d-flex justify-content-between align-items-center w-100">
              <div>
                <h2 class="font-size-18">{{ menuName }}</h2>
              </div>
            </div>
          </div>
          <div class="card-body mt-3">
            <template v-if="menuActive === 1">
              <SchoolYear />
            </template>
            <template v-if="menuActive === 2">
              <study-program />
            </template>
            <template v-if="menuActive === 3">
              <group />
            </template>
            <template v-if="menuActive === 4">
              <level />
            </template>
            <template v-if="menuActive === 5">
              <teacher />
            </template>
            <template v-if="menuActive === 6">
              <student />
            </template>
            <template v-if="menuActive === 7">
              <subject />
            </template>
            <template v-if="menuActive === 8">
              <TableExtracurricular />
            </template>
            <template v-if="menuActive === 10">
              <reward-punishment />
            </template>
            <template v-if="menuActive === 14">
              <Activities />
            </template>
            <template v-if="menuActive === 11">
              <template-description />
            </template>
            <template v-if="menuActive === 'coreCompetence'">
              <core-competence />
            </template>
            <template v-if="menuActive === 'scheduleSetting'">
              <ScheduleSetting />
            </template>
            <template v-if="menuActive === 12">
              <upper-management />
            </template>
            <template v-if="menuActive === 13">
              <institution-setting />
            </template>
            <template v-if="menuActive === 'predicate'">
              <Predicate />
            </template>
            <template v-if="menuActive === 'passingGrade'">
              <PassingGrade />
            </template>
          </div>
        </div>
      </a-col>
    </a-row>
  </div>
</template>

<script>
const Menu = () => import('@/components/app/MenuV2')
const StudyProgram = () => import('@/views/Admin/Master/Program')
const SchoolYear = () => import('@/views/Admin/Master/SchoolYear')
const Subject = () => import('@/views/Admin/Master/Subject')
const Group = () => import('@/views/Admin/Master/Group')
const Level = () => import('@/views/Admin/Master/Level')
const Teacher = () => import('@/views/Admin/Master/Teacher')
const Student = () => import('@/views/Admin/Master/Student')
const TableExtracurricular = () => import('@/views/Admin/Master/Extracurricular')
const ScheduleSetting = () => import('@/views/Admin/Master/ScheduleSetting')
const RewardPunishment = () => import('@/views/Admin/Master/RewardPunishment')
const Activities = () => import('@/views/Admin/Master/Activities')
const TemplateDescription = () => import('@/views/Admin/TemplateDescription')
const UpperManagement = () => import('@/views/Admin/Master/UpperManagement')
const InstitutionSetting = () => import('@/views/Admin/Master/InstitutionSetting')
const CoreCompetence = () => import('@/views/Admin/Master/CoreCompetence')
const Predicate = () => import('@/views/Admin/Master/Predicate')
const PassingGrade = () => import('@/views/Admin/Master/PassingGrade')

const menus = [
  {
    key: 5,
    menu: 'Teacher',
  },
  {
    key: 6,
    menu: 'Student',
  },
  {
    key: 1,
    menu: 'Academic Year',
  },
  {
    sub: true,
    menu: 'Academic Program',
    children: [
      {
        key: 2,
        menu: 'Study Program',
      },
      {
        key: 3,
        menu: 'Group',
      },
      {
        key: 4,
        menu: 'Level',
      },
      {
        key: 7,
        menu: 'Subject',
      },
      {
        key: 'coreCompetence',
        menu: 'Core Competence',
      },
      {
        key: 8,
        menu: 'Extracurricular',
      },
      {
        key: 'scheduleSetting',
        menu: 'Schedule Setting',
      },
    ],
  },
  {
    key: 9,
    menu: 'Student Report Card',
    sub: true,
    children: [
      {
        key: 'predicate',
        menu: 'Predicate',
      },
      {
        key: 'passingGrade',
        menu: 'Passing Grade',
      },
      {
        key: 10,
        menu: 'Reward & Punishment',
      },
      {
        key: 14,
        menu: 'Activities',
      },
      {
        key: 11,
        menu: 'Template Description',
      },
    ],
  },
  {
    key: 12,
    menu: 'Upper Management',
  },
  {
    key: 13,
    menu: 'Institution Setting',
  },
]

export default {
  name: 'Master',
  components: {
    Menu,
    StudyProgram,
    SchoolYear,
    Subject,
    Level,
    Teacher,
    Student,
    TableExtracurricular,
    RewardPunishment,
    TemplateDescription,
    ScheduleSetting,
    InstitutionSetting,
    Group,
    UpperManagement,
    CoreCompetence,
    Predicate,
    PassingGrade,
    Activities,
  },
  data() {
    return {
      menus,
      menuActive: 1,
    }
  },
  methods: {
    menuChange(payload) {
      this.menuActive = payload
    },
  },
  computed: {
    menuName() {
      let menu = ''
      this.menus.forEach(el => {
        if (el.sub) {
          const find = el.children.filter(elChildren => elChildren.key === this.menuActive)[0]
          if (find !== undefined) {
            menu = find.menu
          }
        } else {
          if (el.key === this.menuActive) {
            menu = el.menu
          }
        }
      })
      return menu
    },
  },
}
</script>

<style lang="css"></style>
